import { useStaticQuery, graphql } from 'gatsby';

const useArenaOverview = () => {
    const { sanityConfigArenaOverview: arenaOverview } = useStaticQuery(graphql`
        query {
            sanityConfigArenaOverview {
                mensArenaSections {
                    label {
                        se
                        en
                    }
                    sectionImage {
                        asset {
                            url
                            _id
                        }
                    }
                    backgroundImage {
                        asset {
                            url
                            _id
                        }
                    }
                    preTitle {
                        se
                        en
                    }
                    title {
                        se
                        en
                    }
                    text {
                        en {
                            _rawContent
                        }
                        se {
                            _rawContent
                        }
                    }
                    pricing {
                        label
                        regularPrice
                        reducedPrice
                    }
                    ctaHref
                    ctaLabel {
                        se
                        en
                    }
                }
                womensArenaSections {
                    label {
                        se
                        en
                    }
                    sectionImage {
                        asset {
                            url
                            _id
                        }
                    }
                    backgroundImage {
                        asset {
                            url
                            _id
                        }
                    }
                    preTitle {
                        se
                        en
                    }
                    title {
                        se
                        en
                    }
                    text {
                        en {
                            _rawContent
                        }
                        se {
                            _rawContent
                        }
                    }
                    pricing {
                        label
                        regularPrice
                        reducedPrice
                    }
                    ctaHref
                    ctaLabel {
                        se
                        en
                    }
                }
            }
        }
    `);

    return arenaOverview;
};

export default useArenaOverview;
