import React, { useCallback, useEffect } from 'react';
import { Helmet } from 'react-helmet';

import * as style from 'styles/components/utils/modal.module.scss';

import FormattedMessage from './FormattedMessage';

const Modal = ({ show, onClose, children, className, containerStyle }) => {
    const closeOnEscape = useCallback(
        (e) => {
            if ((e.charCode || e.keyCode) === 27) {
                onClose();
            }
        },
        [onClose],
    );

    useEffect(() => {
        document.body.addEventListener('keydown', closeOnEscape);
        return () => {
            document.body.addEventListener('keydown', closeOnEscape);
        };
    }, [closeOnEscape]);

    if (!show) {
        return null;
    }

    return (
        <>
            {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
            <div
                className={`${style.modal__overlay} ${containerStyle || ''}`}
                onClick={onClose}
                onKeyDown={onClose}
                role="dialog"
                aria-modal="true"
                aria-label="close"
            >

                {/* Used to remove the scroll of the body when the modal is open */}
                <Helmet>
                    <body data-modal-open="true" />
                </Helmet>

                {/* eslint-disable-next-line */}
                <div
                    className={`${style.modal} bg ${className}`}
                    onClick={(e) => e.stopPropagation()}
                >
                    <button
                        className={`${style.modal__close} btn-reset`}
                        onClick={onClose}
                        type="button"
                        aria-label="close"
                    >
                        <i className="ico-cross" aria-hidden />
                        <span className="visually-hidden">
                            <FormattedMessage id="global.close" />
                        </span>
                    </button>
                    {children}
                </div>
            </div>
        </>
    );
};

export default Modal;
