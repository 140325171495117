import { useContext } from 'react';

import { LangContext } from 'context/LangContext';
import enMessages from 'i18n/en.json';
import seMessages from 'i18n/se.json';

const MESSAGES = {
    en: enMessages,
    se: seMessages,
};

export const getFormattedMessage = (id, lang) => {
    const keys = id.split('.');
    const translateObject = MESSAGES[lang.translationKey];
    let value = '';
    let nextTranslateObject = { ...translateObject };

    keys.forEach(key => {
        if (nextTranslateObject[key]) {
            value = nextTranslateObject[key];
            nextTranslateObject = typeof value !== 'string' && { ...value };
        }
    })

    return value;
};

// { id } prop must have dotted notation 'abc.qwe'
const FormattedMessage = ({ id }) => {
    const { lang } = useContext(LangContext);
    return getFormattedMessage(id, lang);
};

export default FormattedMessage;
