// extracted by mini-css-extract-plugin
export var arenaInfo = "arenaOverview-module--arenaInfo--0rMTI";
export var arenaInfo__arenaImage = "arenaOverview-module--arenaInfo__arenaImage--Bz7Av";
export var arenaInfo__arenaInfoContainer = "arenaOverview-module--arenaInfo__arenaInfoContainer--+9r-N";
export var arenaInfo__arenaOverview = "arenaOverview-module--arenaInfo__arenaOverview--x--JM";
export var arenaInfo__background = "arenaOverview-module--arenaInfo__background--2J-+A";
export var arenaInfo__columnCta = "arenaOverview-module--arenaInfo__columnCta--pHkxo";
export var arenaInfo__container = "arenaOverview-module--arenaInfo__container--kQJYa";
export var arenaInfo__info = "arenaOverview-module--arenaInfo__info--J3I0p";
export var arenaInfo__priceList = "arenaOverview-module--arenaInfo__priceList--6MoM5";
export var arenaInfo__priceListHeader = "arenaOverview-module--arenaInfo__priceListHeader--e5aUl";
export var arenaInfo__priceListHeaderLabel = "arenaOverview-module--arenaInfo__priceListHeaderLabel--m4+s-";
export var arenaInfo__priceListHeaderPrice = "arenaOverview-module--arenaInfo__priceListHeaderPrice--GgZWg";
export var arenaInfo__priceListHeaderPriceReduced = "arenaOverview-module--arenaInfo__priceListHeaderPriceReduced--K-2Jo";
export var arenaInfo__priceListRow = "arenaOverview-module--arenaInfo__priceListRow--xfNFF";
export var arenaInfo__priceListRowLabel = "arenaOverview-module--arenaInfo__priceListRowLabel--Yrda5";
export var arenaInfo__priceListRowPrice = "arenaOverview-module--arenaInfo__priceListRowPrice--4R+Ay";
export var arenaInfo__priceListRowPriceReduced = "arenaOverview-module--arenaInfo__priceListRowPriceReduced--8t95H";
export var arenaInfo__select = "arenaOverview-module--arenaInfo__select--+GkyQ";