module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-Y1H4HC9Z0J"],"pluginConfig":{"head":true,"anonymize_ip":true,"exclude":["/preview/**"]}},
    },{
      plugin: require('../node_modules/gatsby-plugin-rudderstack/gatsby-browser.js'),
      options: {"plugins":[],"prodKey":"2oRB3gIOuJO1D2vN2YIHHzzfneQ","devKey":"2oRB3gIOuJO1D2vN2YIHHzzfneQ","dataPlaneUrl":"https://svenskelit-dataplane.rudderstack.com","trackPage":true,"trackPageDelay":50},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Hammarby Fotboll","short_name":"Hammarby","start_url":"/","background_color":"#092F21","theme_color":"#007A43","display":"minimal-ui","icon":"static/images/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"220179d83663063836fde2b974c578d3"},
    },{
      plugin: require('../node_modules/@sentry/gatsby/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
