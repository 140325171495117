import React, { useContext, useEffect, useState } from 'react';
import ImageGatsby from 'gatsby-plugin-sanity-image';
import { add } from 'date-fns';

import * as style from 'styles/components/misc/splash.module.scss';
import { LangContext } from 'context/LangContext';
import { useAuth } from 'context/UserContext';
import useCommonSplash from 'data/queries/useCommonSplash';
import Modal from 'components/utils/Modal';
import FormattedMessage, { getFormattedMessage } from 'components/utils/FormattedMessage';
import TextEditorRender from 'components/utils/TextEditorRender';
import CustomLink from 'components/utils/CustomLink';

const CommonSplash = () => {
    const { lang } = useContext(LangContext);
    const { isSignedIn } = useAuth();
    const richTextKey = `_raw${lang.hreflang.charAt(0).toUpperCase() + lang.hreflang.slice(1)}`;

    const splashData = useCommonSplash();

    const [isModalOpen, setModalOpen] = useState(false);

    useEffect(() => {
        if (typeof localStorage !== 'undefined') {
            const splashDisplay = localStorage.getItem('splashDisplay');
            const isExpired = splashDisplay && new Date().toISOString() > JSON.parse(splashDisplay)?.expiresAt;

            const showForCurrentUser =
                splashData.displayType === 'all'
                || isSignedIn && splashData.displayType === 'logged'
                || !isSignedIn && splashData.displayType === 'notLogged';

            if (showForCurrentUser && (!splashDisplay || isExpired)) {
                const sessionObject = {
                    expiresAt: add(new Date(), {
                        hours: 24,
                    }),
                };

                localStorage.setItem('splashDisplay', JSON.stringify(sessionObject));
                setModalOpen(true);
            }
        }
    }, [isSignedIn, splashData]);

    if (!splashData || splashData.displayType === 'none') {
        return null;
    }

    /* eslint-disable jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */
    const renderLink = (item, isPrimary) => {
        const cssClass = isPrimary
            ? `cta-yellow ${style.splash__firstLink}`
            : `${style.splash__secondLink}`;

        if (item.page) {
            return (
                <div onClick={() => setModalOpen(false)}>
                    <CustomLink
                        className={cssClass}
                        to={`/${item.page.slug.current}`}
                        target={item.external ? '_blank' : '_self'}
                        isTranslated={false}
                    >
                        {item.title}
                    </CustomLink>
                </div>
            );
        }

        if (!item.page && item.externalUrl) {
            return (
                <div onClick={() => setModalOpen(false)}>
                    <a
                        className={cssClass}
                        href={item.externalUrl}
                        rel="noreferrer"
                        target={item.external ? '_blank' : '_self'}
                    >
                        {item.title}
                    </a>
                </div>
            );
        }

        return (
            <button type="button" onClick={() => setModalOpen(false)} aria-label={getFormattedMessage('global.close', lang)}>
                <FormattedMessage id="global.close" />
            </button>
        );
    };

    return (
        <Modal
            show={isModalOpen}
            onClose={() => setModalOpen(false)}
            className={style.splash__modal}
        >
            <div className={style.splash}>
                {splashData.visual && (
                    <ImageGatsby
                        {...splashData.visual}
                        alt={splashData.headline[lang.translationKey]}
                        className={style.splash__image}
                    />
                )}

                <div className={style.splash__content}>
                    <div className={style.splash__title}>
                        {splashData.headline[lang.translationKey]}
                    </div>

                    <div className={style.splash__text}>
                        <TextEditorRender content={splashData.text[richTextKey]} />
                    </div>

                    {splashData?.primaryLink[lang.translationKey] && (
                        renderLink(splashData.primaryLink[lang.translationKey], true)
                    )}

                    {splashData?.secondaryLink[lang.translationKey] && (
                        renderLink(splashData.secondaryLink[lang.translationKey], false)
                    )}
                </div>
            </div>
        </Modal>
    );
};

export default CommonSplash;
