// extracted by mini-css-extract-plugin
export var takeOver__closeWrapper = "takeOver2024-module--takeOver__closeWrapper--cKDwl";
export var takeOver__column = "takeOver2024-module--takeOver__column--uAqXb";
export var takeOver__columnCta = "takeOver2024-module--takeOver__columnCta--5XHWU";
export var takeOver__columnNarrow = "takeOver2024-module--takeOver__columnNarrow--2qp6R";
export var takeOver__columnPreamble = "takeOver2024-module--takeOver__columnPreamble--Z0M+J";
export var takeOver__columnWide = "takeOver2024-module--takeOver__columnWide---LMKE";
export var takeOver__contact = "takeOver2024-module--takeOver__contact--X5MXm";
export var takeOver__content = "takeOver2024-module--takeOver__content--hClfr";
export var takeOver__counter = "takeOver2024-module--takeOver__counter--vuV8K";
export var takeOver__counterBody = "takeOver2024-module--takeOver__counterBody--83rQB";
export var takeOver__counterDivider = "takeOver2024-module--takeOver__counterDivider--BlHaE";
export var takeOver__counterDividerDot = "takeOver2024-module--takeOver__counterDividerDot--w8nju";
export var takeOver__counterFooter = "takeOver2024-module--takeOver__counterFooter--u+4+f";
export var takeOver__counterHeader = "takeOver2024-module--takeOver__counterHeader--iO9xH";
export var takeOver__counterNumber = "takeOver2024-module--takeOver__counterNumber--9uYnn";
export var takeOver__counterTitle = "takeOver2024-module--takeOver__counterTitle--CYTKd";
export var takeOver__counterWrapper = "takeOver2024-module--takeOver__counterWrapper--JqApl";
export var takeOver__embedWrapper = "takeOver2024-module--takeOver__embedWrapper--EJ2fC";
export var takeOver__header = "takeOver2024-module--takeOver__header--+TzN+";
export var takeOver__logo = "takeOver2024-module--takeOver__logo--uRi+9";
export var takeOver__row = "takeOver2024-module--takeOver__row--z5o0w";
export var takeOver__rowColumns = "takeOver2024-module--takeOver__rowColumns--zuVn0";
export var takeOver__seasonCard = "takeOver2024-module--takeOver__seasonCard--EW77q";
export var takeOver__seasonCardMen = "takeOver2024-module--takeOver__seasonCardMen--N7PqU";
export var takeOver__seasonCardWomen = "takeOver2024-module--takeOver__seasonCardWomen--U9YnN";
export var takeOver__service = "takeOver2024-module--takeOver__service--anpxN";
export var takeOver__serviceColumn = "takeOver2024-module--takeOver__serviceColumn--b-R0Z";
export var takeOver__serviceContainer = "takeOver2024-module--takeOver__serviceContainer--xu3vP";
export var takeOver__serviceRow = "takeOver2024-module--takeOver__serviceRow--5ko8y";
export var takeOver__textBlock = "takeOver2024-module--takeOver__textBlock--vAdzi";
export var takeOver__timeLine = "takeOver2024-module--takeOver__timeLine--m51s5";
export var takeOver__timeLineImage = "takeOver2024-module--takeOver__timeLineImage--ljE2G";
export var takeOver__timeLineImageMobile = "takeOver2024-module--takeOver__timeLineImageMobile--G8Cnu";
export var takeOver__video = "takeOver2024-module--takeOver__video--M14ZW";
export var takeOver__wrapper = "takeOver2024-module--takeOver__wrapper--PenDg";
export var takeoverNoScroll = "takeOver2024-module--takeoverNoScroll--sC1zD";