import { useStaticQuery, graphql } from 'gatsby';

const useCommonSplash = () => {
    const { sanityConfigSplash: commonSplash } = useStaticQuery(graphql`
        fragment MenuItem on SanityMenuItem {
            title
            external
            externalUrl
            page {
                slug {
                    current
                }
            }
        }
        query {
            sanityConfigSplash {
                visual {
                    ...ImageWithPreview
                }
                headline {
                    en
                    se
                }
                text {
                    _rawEn
                    _rawSe
                }
                displayType
                primaryLink {
                    se {
                        ...MenuItem
                    }
                    en {
                        ...MenuItem
                    }
                }
                secondaryLink {
                    se {
                        ...MenuItem
                    }
                    en {
                        ...MenuItem
                    }
                }
            }
        }
    `);

    return commonSplash;
};

export default useCommonSplash;
