import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { StaticImage } from 'gatsby-plugin-image';
import Countdown from 'react-countdown';

import Logo from 'components/misc/Logo';
import ArenaOverview from 'components/utils/ArenaOverview';
import * as style from 'styles/components/utils/takeOver2024.module.scss';

import i01 from '../../images/takeover/2025/process-01.svg';
import i02 from '../../images/takeover/2025/process-02.svg';
import i03 from '../../images/takeover/2025/process-03.svg';
import i04 from '../../images/takeover/2025/process-04.svg';
import i05 from '../../images/takeover/2025/process-05.svg';
import i06 from '../../images/takeover/2025/process-06.svg';
import i07 from '../../images/takeover/2025/process-07.svg';
import i08 from '../../images/takeover/2025/process-08.svg';
import mi01 from '../../images/takeover/2025/m-process-01.svg';
import mi02 from '../../images/takeover/2025/m-process-02.svg';
import mi03 from '../../images/takeover/2025/m-process-03.svg';
import mi04 from '../../images/takeover/2025/m-process-04.svg';
import mi05 from '../../images/takeover/2025/m-process-05.svg';
import mi06 from '../../images/takeover/2025/m-process-06.svg';
import mi07 from '../../images/takeover/2025/m-process-07.svg';
import mi08 from '../../images/takeover/2025/m-process-08.svg';

const TakeOver = ({ takeoverConfig }) => {

    const countdownDate = takeoverConfig.countdownDate ? new Date(takeoverConfig.countdownDate) : null;
    const soldWomen = takeoverConfig.soldCardsWomen ? takeoverConfig.soldCardsWomen : null;
    const soldMen = takeoverConfig.soldCardsMen ? takeoverConfig.soldCardsMen : null;
    const youtubeVideoId = takeoverConfig.youtubeVideoId ? takeoverConfig.youtubeVideoId : null;

    const [isTakeOverActive, toggleTakeOver] = useState(true);

    function fakeNavigation() {
        toggleTakeOver(false);
        document.getElementById("seasonalTakeover").remove();
    }

    const buildTimeline = () => {

        const currentDate = new Date();

        // All the phases in the timeline
        const p02Start = new Date('2024-11-13T00:00:00');
        const p02End = new Date('2024-12-04T23:59:59');

        const p03Start = new Date('2024-12-05T00:00:00');
        const p03End = new Date('2024-12-06T23:59:59');

        const p04Start = new Date('2024-12-07T00:00:00');
        const p04End = new Date('2024-12-11T23:59:59');

        const p05Start = new Date('2024-12-12T00:00:00');
        const p05End = new Date('2025-01-12T23:59:59');

        const p06Start = new Date('2025-01-13T00:00:00');
        const p06End = new Date('2025-01-19T23:59:59');

        const p07Start = new Date('2025-01-20T00:00:00');
        const p07End = new Date('2025-02-08T23:59:59');

        // const p08Start = new Date('2024-01-09T00:00:00');
        let timelineImage = i01;
        let timelineImageMobile = mi01;
        if( currentDate < p02Start) {
            timelineImage = i01;
            timelineImageMobile = mi01;
        } else if (currentDate >= p02Start && currentDate <= p02End) {
            timelineImage = i02;
            timelineImageMobile = mi02;
        } else if (currentDate >= p03Start && currentDate <= p03End) {
            timelineImage = i03;
            timelineImageMobile = mi03;
        } else if (currentDate >= p04Start && currentDate <= p04End) {
            timelineImage = i04;
            timelineImageMobile = mi04;
        } else if (currentDate >= p05Start && currentDate <= p05End) {
            timelineImage = i05;
            timelineImageMobile = mi05;
        } else if (currentDate >= p06Start && currentDate <= p06End) {
            timelineImage = i06;
            timelineImageMobile = mi06;
        } else if (currentDate >= p07Start && currentDate <= p07End) {
            timelineImage = i07;
            timelineImageMobile = mi07;
        } else if(currentDate > p07End) {
            timelineImage = i08;
            timelineImageMobile = mi08;
        }
        return (
            <div className={style.takeOver__timeLine}>
                <img src={timelineImage} className={style.takeOver__timeLineImage} alt="Säsongskort - Tidslinje för händelser" />
                <img src={timelineImageMobile} className={style.takeOver__timeLineImageMobile} alt="Säsongskort - Tidslinje för händelser" />
            </div>
        )
    };

    const renderer = ({ completed, formatted }) => {
        if (completed) {
            return (
                <span className={style.takeOver__counter}>
                    .
                </span>
            );
        }
        return (
            <div className={style.takeOver__counter}>
                <div className={style.takeOver__counterHeader}>
                    <div className={style.takeOver__counterTitle}>
                        Dagar
                    </div>
                    <div className={style.takeOver__counterTitle}>
                        Timmar
                    </div>
                    <div className={style.takeOver__counterTitle}>
                        Minuter
                    </div>
                    <div className={style.takeOver__counterTitle}>
                        Sekunder
                    </div>
                </div>
                <div className={style.takeOver__counterBody}>
                    <div className={style.takeOver__counterNumber}>
                        {formatted.days}
                    </div>
                    <div className={style.takeOver__counterDivider}>
                        <div className={style.takeOver__counterDividerDot} />
                        <div className={style.takeOver__counterDividerDot} />
                    </div>
                    <div className={style.takeOver__counterNumber}>
                        {formatted.hours}
                    </div>
                    <div className={style.takeOver__counterDivider}>
                        <div className={style.takeOver__counterDividerDot} />
                        <div className={style.takeOver__counterDividerDot} />

                    </div>
                    <div className={style.takeOver__counterNumber}>
                        {formatted.minutes}
                    </div>
                    <div className={style.takeOver__counterDivider}>
                        <div className={style.takeOver__counterDividerDot} />
                        <div className={style.takeOver__counterDividerDot} />
                    </div>
                    <div className={style.takeOver__counterNumber}>
                        {formatted.seconds}
                    </div>
                </div>
                <div className={style.takeOver__counterFooter}>
                    Tills förnyelseperioden stänger
                </div>
            </div>
        );
    };

    return (
        <div id="seasonalTakeover" className={style.takeOver__wrapper}>
            {isTakeOverActive &&
            <Helmet>
                <body data-modal-open="true" />
            </Helmet>
            }
            <div className={style.takeOver__logo}>
                <Logo black />
            </div>

            <div className={style.takeOver__closeWrapper}>
                <button type="button" onClick={() => fakeNavigation(false)}>Hammarbyfotboll.se</button>
            </div>

            <div className={style.takeOver__content}>

                <div className={style.takeOver__counterWrapper}>
                    <Countdown date={countdownDate} renderer={renderer} zeroPadTime={2} />
                </div>
                <div className={style.takeOver__header}>
                    <h1>
                        Köp säsongskort till våra läktare
                    </h1>
                    <p>
                        Det är dags att fylla våra läktare inför en ny säsong.<br />
                        Från och med den 13 november kan du förnya ditt säsongskort.
                    </p>
                </div>
                {youtubeVideoId && (
                    <div className={style.takeOver__video}>
                        <div className={style.takeOver__embedWrapper}>
                            <iframe src={`https://www.youtube.com/embed/${youtubeVideoId}`} title="Hammarby Fotboll" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen />
                        </div>
                    </div>
                )}

                {buildTimeline()}

                <div className={style.takeOver__rowColumns}>
                    <div className={`${style.takeOver__column} ${style.takeOver__columnWide}`}>
                        {soldMen && (
                            <p className={style.takeOver__columnPreamble}>
                                {soldMen} sålda säsongskort till Allsvenskan
                            </p>
                        )}
                        <h2>
                            Säsongskort till herrlaget 2025
                        </h2>
                        <p>
                            Årets upplaga av Allsvenskan är över, där Hammarby slutade på en finfin andraplats. Trenden pekar stadigt uppåt och det starka stödet från Hammarbys supportrar har lyft laget till nya höjder. Nu är det dags att blicka framåt och säkra din plats på läktaren 2025. Gruppspelsmatcherna i Svenska Cupen får du på köpet. Vi ses på läktaren!
                        </p>
                        <a className={style.takeOver__columnCta} href="https://hammarbyherr.ebiljett.nu/Account/AxsSdk" target="_blank" rel="noreferrer">Förnya säsongskortet till Allsvenskan</a>
                    </div>

                    <div className={`${style.takeOver__column} ${style.takeOver__columnNarrow}`}>
                        <div className={`${style.takeOver__seasonCard} ${style.takeOver__seasonCardMen}`}>
                            <StaticImage
                                src="../../images/takeover/2025/sc-herr-2025.png"
                                alt="Hammarby Fotboll - Allsvenskan"
                                loading="lazy"
                            />
                        </div>
                    </div>
                </div>

                <ArenaOverview />

                <div className={style.takeOver__rowColumns}>
                    <div className={`${style.takeOver__column} ${style.takeOver__columnWide}`}>
                        {soldWomen && (
                            <p className={style.takeOver__columnPreamble}>
                                {soldWomen} sålda säsongskort
                            </p>
                        )}
                        <h2>
                            Säsongskort till Damlaget 2025
                        </h2>
                        <p>
                            För andra året i rad säkrade Hammarbys damlag Europaspel, efter en tredjeplats i OBOS Damallsvenskan. Lokomotivet för svensk fotboll på damsidan ångar vidare och tillsammans med Sveriges bästa fans kommer laget att fortsätta skörda framgångar även nästa år. Nu är det dags att säkra din plats på Hammarby IP 2025. Vi ses på läktaren!
                        </p>
                        <a className={style.takeOver__columnCta} href="https://hammarbydam.ebiljett.nu/Account" target="_blank" rel="noreferrer">Förnya säsongskortet till OBOS Damallsvenskan</a>
                    </div>
                    <div className={`${style.takeOver__column} ${style.takeOver__columnNarrow}`}>
                        <div className={`${style.takeOver__seasonCard} ${style.takeOver__seasonCardWomen}`}>
                            <StaticImage
                                src="../../images/takeover/2025/sc-dam-2025.png"
                                alt="Hammarby Fotboll - Obos damallsvenskan"
                                loading="lazy"
                            />
                        </div>
                    </div>
                </div>

                <ArenaOverview arenaType="women" />

                <div className={style.takeOver__textBlock}>
                    <h2>Dela upp dina säsongskort</h2>
                    <p>
                        Om du köper fler säsongskort kommer alla säsongskort att bli kopplade till ett konto med samma namn, något som inte går att ändra efter genomfört köp. Fördelen med att ha ett eget konto är att du själv kan administrera ditt eget säsongskort, skicka vidare matchbiljetter, ta del av nyhetsbrev och förköpskoder samt få ditt eget namn tryckt på kortet.
                    </p>
                    <p>
                        Maila <a href="mailto:biljetter@hammarbyfotboll.se">biljetter@hammarbyfotboll.se</a> så hjälper vi dig!
                    </p>
                </div>

                <div className={style.takeOver__textBlock}>
                    <h2>Säsongskortskön</h2>
                    <p>
                        Efter förnyelseperioden av säsongskorten till Allsvenskan 2025 kommer de säsongskort som inte förnyas bli tillgängliga för alla supportrar precis som vanligt och går då i första hand i turordning till de som ställt sig i kön. <a href="https://ko.hammarbyfotboll.se" target="_blank" rel="noreferrer">Klicka här för att komma till kön.</a>
                    </p>
                </div>

                <div className={style.takeOver__textBlock}>
                    <h2>OBS! Nyförsäljningen börjar 20 februari</h2>
                    <p>
                        Efter förnyelseperioden är det platsbytardagar för de som har förnyat sina kort. Därefter erbjuds de ej förnyade säsongskorten till försäljning för de som står i kön. Efter det drar nyförsäljningsperioden i gång för både dam och herrlaget.
                    </p>
                </div>

            </div>

            <div className='style.takeOver__footer'>
                <div className={style.takeOver__service}>
                    <div className={style.takeOver__serviceContainer}>
                        <h3>Öppettider servicecenter</h3>
                        <div className={style.takeOver__serviceRow}>
                            <div className={style.takeOver__serviceColumn}>

                                <h4>Onsdag 13 november</h4>
                                <p>12.00-17.00</p>
                                <h4>Torsdag 14 november</h4>
                                <p>9.00-17.00</p>
                                <h4>Fredag 15 november</h4>
                                <p>9.00-16.00</p>
                                <h4>Måndag 18 november</h4>
                                <p>
                                    Telefon 9.00-12.00<br />
                                    mejl/chatt 9.00-17.00
                                </p>
                            </div>
                            <div className={style.takeOver__serviceColumn}>
                                <h4>Tisdag 19 november</h4>
                                <p>
                                    Telefon 9.00-12.00<br />
                                    mejl/chatt 9.00-17.00
                                </p>
                                <h4>Onsdag 20 november</h4>
                                <p>
                                    Telefon 9.00-12.00<br />
                                    mejl/chatt 9.00-17.00
                                </p>
                                <h4>Torsdag 21 november</h4>
                                <p>
                                    Telefon 13.00-17.00<br />
                                    mejl/chatt 9.00-17.00
                                </p>
                                <h4>Fredag 22 november</h4>
                                <p>
                                    Endast mejl/chatt.
                                </p>
                            </div>
                            <div className={style.takeOver__serviceColumn}>
                                <h4>Kontakt</h4>
                                <p>
                                    Telefonnummer: <strong><a href="phone:084628813">08 462 88 13</a></strong><br />
                                    Email: <strong><a href="mailto:biljetter@hammarbyfotboll.se">biljetter@hammarbyfotboll.se</a></strong><br />
                                    Information: <strong><a href="https://hammarbyfotboll.se/biljetter#sasongskort">https://hammarbyfotboll.se/biljetter#sasongskort</a></strong>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
};
export default TakeOver;
