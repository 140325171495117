/**
 * Add the language prefix in the url if the page isn't swedish (se)
 */
const formatPageSlug = (slug, pageLang) => {
    if (slug === '/' && pageLang !== 'se') return `/${pageLang}/`;

    return pageLang !== 'se' ? `/${pageLang}/${slug}` : slug;
};

const breakpoints = {
    large: 1200,
    medium: 850,
    small: 500,
};

// Todo: Uncomment the english Lang when the website will need to be in english
const availableLang = [
    {
        hreflang: 'se',
        iso: 'sv-SE',
        translationKey: 'se',
    },
    // {
    //     hreflang: 'en',
    //     iso: 'en-EN',
    //     translationKey: 'en',
    // },
];

const fromPortableToText = (blocks) => {
    if (blocks) {
        return blocks
            .reduce((result, element) => {
                if (element._type === 'block' && element.children) {
                    result.push(element.children.map((child) => child.text).join(''));
                }
                return result;
            }, [])
            .join('\n\n');
    }
    return '';
};

module.exports = { formatPageSlug, breakpoints, availableLang, fromPortableToText };
