import * as React from 'react';

const EmbedVideoPlayer = ({ url, title }) =>
    <div className="video-embed">
        <iframe
            title={title}
            src={url}
            height="100%"
            width="100%"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
        />
    </div>;

export default EmbedVideoPlayer;
