import React, { useContext } from 'react';
import { StaticImage } from 'gatsby-plugin-image';

import * as style from 'styles/components/footer/footer.module.scss';
import Logo from 'components/misc/Logo';
import Menu from 'components/footer/Menu';
import Partners from 'components/footer/Partners';
import Sponsors from 'components/footer/Sponsors';
import FormattedMessage from 'components/utils/FormattedMessage';
import { LangContext } from 'context/LangContext';

/* TODO Do the translation of the static texts */

const Footer = ({ footerConfig }) => {
    const { lang } = useContext(LangContext);

    return (
        <footer className={style.footer}>
            <Partners />
            <Sponsors />
            <div className={`wrapper ${style.footer__container}`}>
                <div className={style.footer__column}>
                    <div className={style.footer__logo}>
                        <Logo white />
                    </div>
                    {footerConfig.description && (
                        <p className={style.footer__description}>
                            {footerConfig.description[lang.translationKey]}
                        </p>
                    )}
                </div>

                <div className={style.footer__column}>
                    <Menu data={footerConfig.menu} />
                </div>

                <div className={style.footer__column}>
                    {/* Todo integrate the newsletter subscription module */}

                    <div>
                        <ul>
                            {footerConfig.facebookUrl && (
                                <a
                                    className={style.footer__social}
                                    href={footerConfig.facebookUrl}
                                    target="_blank"
                                    title="Hammarby Facebook"
                                    rel="noreferrer"
                                    aria-label="Hammarby Facebook"
                                >
                                    <i className="ico-facebook" aria-hidden />
                                </a>
                            )}

                            {footerConfig.twitterUrl && (
                                <a
                                    className={style.footer__social}
                                    href={footerConfig.twitterUrl}
                                    target="_blank"
                                    title="Hammarby Twitter"
                                    rel="noreferrer"
                                    aria-label="Hammarby Twitter"
                                >
                                    <i className="ico-twitter" aria-hidden />
                                </a>
                            )}

                            {footerConfig.instagramUrl && (
                                <a
                                    className={style.footer__social}
                                    href={footerConfig.instagramUrl}
                                    target="_blank"
                                    title="Hammarby Instagram"
                                    rel="noreferrer"
                                    aria-label="Hammarby Instagram"
                                >
                                    <i className="ico-instagram" aria-hidden />
                                </a>
                            )}

                            {footerConfig.youtubeUrl && (
                                <a
                                    className={style.footer__social}
                                    href={footerConfig.youtubeUrl}
                                    target="_blank"
                                    title="Hammarby Youtube"
                                    rel="noreferrer"
                                    aria-label="Hammarby Youtube"
                                >
                                    <i className="ico-youtube" aria-hidden />
                                </a>
                            )}

                            {footerConfig.linkedinUrl && (
                                <a
                                    className={style.footer__social}
                                    href={footerConfig.linkedinUrl}
                                    target="_blank"
                                    title="Hammarby Linkedin"
                                    rel="noreferrer"
                                    aria-label="Hammarby Linkedin"
                                >
                                    <i className="ico-linkedin" aria-hidden />
                                </a>
                            )}

                            {footerConfig.tiktokUrl && (
                                <a
                                    className={style.footer__social}
                                    href={footerConfig.tiktokUrl}
                                    target="_blank"
                                    title="Hammarby Tiktok"
                                    rel="noreferrer"
                                    aria-label="Hammarby Tiktok"
                                >
                                    <i className="ico-tiktok" aria-hidden />
                                </a>
                            )}

                        </ul>
                    </div>

                    <p className="text-base text-semibold">
                        <FormattedMessage id="footer.getApp" />
                    </p>

                    <div className={style.footer__appsContainer}>
                        {footerConfig.iosAppUrl && (
                            <a href={footerConfig.iosAppUrl} target="_blank" rel="noreferrer">
                                <StaticImage
                                    src="../../images/appStore.svg"
                                    alt="Download on the App Store"
                                    width={135}
                                    loading="lazy"
                                />
                            </a>
                        )}

                        {footerConfig.androidAppUrl && (
                            <a href={footerConfig.androidAppUrl} target="_blank" rel="noreferrer">
                                <StaticImage
                                    src="../../images/googlePlay.svg"
                                    alt="Get it on Google Play"
                                    width={135}
                                    loading="lazy"
                                />
                            </a>
                        )}
                    </div>
                </div>
            </div>
            <div className={`wrapper ${style.footer__copyright}`}>
                <p>
                    © {(new Date().getFullYear())} Hammarby Fotboll. <FormattedMessage id="footer.copyright" />
                </p>
            </div>
        </footer>
    );
};

export default Footer;
