import * as React from 'react';
import getYouTubeId from 'get-youtube-id';
import YouTube from 'react-youtube';
import { PortableText } from '@portabletext/react';
import SanityImage from 'gatsby-plugin-sanity-image';

import EmbedVideoPlayer from 'components/utils/EmbedVideoPlayer';

const serializers = {
    marks: {
        link: ({ value, children }) => {
            const { blank, href } = value
            return blank ?
              <a href={href} target="_blank" rel="noreferrer">{children}</a>
              : <a href={href}>{children}</a>
        },
        assetReference: ({ value, children }) => {
            const [file, id, extension] = value.file.asset._ref.split('-');
            return (
                <a href={`https://cdn.sanity.io/files/${process.env.GATSBY_SANITY_PROJECT_ID}/${process.env.GATSBY_SANITY_PROJECT_DATASET}/${id}.${extension}?dl=${id}.${extension}`} data-type={file}>{children}</a>
            );
        },
    },
    types: {
        blockEditorYoutube: ({ value }) => {
            const { url } = value;
            const id = getYouTubeId(url) || undefined;
            return <YouTube videoId={id} className="video ratio-16-9" />;
        },
        blockEditorSolidtango: ({ value }) => {
            const { url } = value;
            return (
                <EmbedVideoPlayer url={url} />
            );
        },
        blockEditorSEF: ({ value }) => {
            const { url } = value;
            return (
                <EmbedVideoPlayer url={url} />
            );
        },
        blockEditorStaylive: ({ value }) => {
            const { url } = value;
            return (
                <EmbedVideoPlayer url={url} />
            );
        },
        blockEditorImage: ({ value }) => {
            const { image, alt, link } = value;

            if (!image || !image.asset) {
                return null;
            }

            if (link) {
                return (
                    <a href={link} target="_blank" rel="noreferrer">
                        <SanityImage
                            alt={alt !== undefined ? alt : ''}
                            asset={image.asset}
                            hotspot={image.hotspot}
                            crop={image.crop}
                            width={undefined}
                            height={undefined}
                            sources={undefined}
                            __typename={undefined}
                            _type={undefined}
                            _key={undefined}
                        />
                    </a>
                );
            }

            return (
                <SanityImage
                    alt={alt !== undefined ? alt : ''}
                    asset={image.asset}
                    hotspot={image.hotspot}
                    crop={image.crop}
                    width={undefined}
                    height={undefined}
                    sources={undefined}
                    __typename={undefined}
                    _type={undefined}
                    _key={undefined}
                />
            );
        },
    },
};

const TextEditorRender = ({ content }) => (
    <div className="richtext">
        <PortableText value={content} components={serializers} />
    </div>
);

export default TextEditorRender;
