import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'gatsby';

import { LangContext } from 'context/LangContext';
import { useAuth } from 'context/UserContext';
import FormattedMessage, { getFormattedMessage } from 'components/utils/FormattedMessage';

const AccountLink = ({ onClickLink }) => {
    const { lang } = useContext(LangContext);
    const { isSignedIn, signIn, userName } = useAuth();

    const [skipLoginPage, setSkipLoginPage] = useState(false);

    useEffect(() => {
        if (
            typeof localStorage === 'undefined' ||
            !localStorage.getItem('skipLoginPage') ||
            !JSON.parse(localStorage.getItem('skipLoginPage'))
        ) {
            return;
        }

        setSkipLoginPage(true);
    }, [setSkipLoginPage]);

    return (
        <div>
            {isSignedIn && (
                <Link to="/account-tickets" onClick={onClickLink}>
                    <i className="ico-user" aria-hidden="true" />
                    <span>{userName.split(' ')[0]}</span>
                </Link>
            )}

            {!isSignedIn && skipLoginPage && (
                <button type="button" onClick={signIn} className="cta-link">
                    <i className="ico-user" aria-hidden="true" aria-label={getFormattedMessage('global.login', lang)} />
                    <FormattedMessage id="global.logIn" />
                </button>
            )}

            {!isSignedIn && !skipLoginPage && (
                <Link to="/login" onClick={onClickLink}>
                    <i className="ico-user" aria-hidden="true" />
                    <span><FormattedMessage id="global.logIn" /></span>
                </Link>
            )}
        </div>
    );
};

export default AccountLink;
