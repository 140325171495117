import { useStaticQuery, graphql } from 'gatsby';

const useAllPartners = () => {
    const {
        allSanityPartner: { nodes: partners },
    } = useStaticQuery(graphql`
        query {
            allSanityPartner {
                nodes {
                    title
                    enabled
                    priority
                    url
                    logoBlack {
                        asset {
                            url
                            altText
                        }
                    }
                }
            }
        }
    `);

    return partners;
};

export default useAllPartners;
