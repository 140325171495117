exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-preview-[id]-jsx": () => import("./../../../src/pages/preview/[id].jsx" /* webpackChunkName: "component---src-pages-preview-[id]-jsx" */),
  "component---src-templates-about-jsx": () => import("./../../../src/templates/about.jsx" /* webpackChunkName: "component---src-templates-about-jsx" */),
  "component---src-templates-account-membership-jsx": () => import("./../../../src/templates/account/membership.jsx" /* webpackChunkName: "component---src-templates-account-membership-jsx" */),
  "component---src-templates-account-membership-offers-jsx": () => import("./../../../src/templates/account/membershipOffers.jsx" /* webpackChunkName: "component---src-templates-account-membership-offers-jsx" */),
  "component---src-templates-account-profile-jsx": () => import("./../../../src/templates/account/profile.jsx" /* webpackChunkName: "component---src-templates-account-profile-jsx" */),
  "component---src-templates-account-season-pass-jsx": () => import("./../../../src/templates/account/seasonPass.jsx" /* webpackChunkName: "component---src-templates-account-season-pass-jsx" */),
  "component---src-templates-account-tickets-jsx": () => import("./../../../src/templates/account/tickets.jsx" /* webpackChunkName: "component---src-templates-account-tickets-jsx" */),
  "component---src-templates-arena-info-men-jsx": () => import("./../../../src/templates/arenaInfoMen.jsx" /* webpackChunkName: "component---src-templates-arena-info-men-jsx" */),
  "component---src-templates-arena-info-women-jsx": () => import("./../../../src/templates/arenaInfoWomen.jsx" /* webpackChunkName: "component---src-templates-arena-info-women-jsx" */),
  "component---src-templates-basic-jsx": () => import("./../../../src/templates/basic.jsx" /* webpackChunkName: "component---src-templates-basic-jsx" */),
  "component---src-templates-buy-tickets-jsx": () => import("./../../../src/templates/buyTickets.jsx" /* webpackChunkName: "component---src-templates-buy-tickets-jsx" */),
  "component---src-templates-club-partners-jsx": () => import("./../../../src/templates/clubPartners.jsx" /* webpackChunkName: "component---src-templates-club-partners-jsx" */),
  "component---src-templates-clubchair-partners-jsx": () => import("./../../../src/templates/clubchairPartners.jsx" /* webpackChunkName: "component---src-templates-clubchair-partners-jsx" */),
  "component---src-templates-contact-jsx": () => import("./../../../src/templates/contact.jsx" /* webpackChunkName: "component---src-templates-contact-jsx" */),
  "component---src-templates-csr-partners-jsx": () => import("./../../../src/templates/csrPartners.jsx" /* webpackChunkName: "component---src-templates-csr-partners-jsx" */),
  "component---src-templates-faq-jsx": () => import("./../../../src/templates/faq.jsx" /* webpackChunkName: "component---src-templates-faq-jsx" */),
  "component---src-templates-heelkick-partners-jsx": () => import("./../../../src/templates/heelkickPartners.jsx" /* webpackChunkName: "component---src-templates-heelkick-partners-jsx" */),
  "component---src-templates-homepage-jsx": () => import("./../../../src/templates/homepage.jsx" /* webpackChunkName: "component---src-templates-homepage-jsx" */),
  "component---src-templates-login-jsx": () => import("./../../../src/templates/login.jsx" /* webpackChunkName: "component---src-templates-login-jsx" */),
  "component---src-templates-main-partners-jsx": () => import("./../../../src/templates/mainPartners.jsx" /* webpackChunkName: "component---src-templates-main-partners-jsx" */),
  "component---src-templates-match-item-jsx": () => import("./../../../src/templates/matchItem.jsx" /* webpackChunkName: "component---src-templates-match-item-jsx" */),
  "component---src-templates-match-list-jsx": () => import("./../../../src/templates/matchList.jsx" /* webpackChunkName: "component---src-templates-match-list-jsx" */),
  "component---src-templates-membership-jsx": () => import("./../../../src/templates/membership.jsx" /* webpackChunkName: "component---src-templates-membership-jsx" */),
  "component---src-templates-news-item-jsx": () => import("./../../../src/templates/newsItem.jsx" /* webpackChunkName: "component---src-templates-news-item-jsx" */),
  "component---src-templates-news-list-jsx": () => import("./../../../src/templates/newsList.jsx" /* webpackChunkName: "component---src-templates-news-list-jsx" */),
  "component---src-templates-official-partners-jsx": () => import("./../../../src/templates/officialPartners.jsx" /* webpackChunkName: "component---src-templates-official-partners-jsx" */),
  "component---src-templates-partners-jsx": () => import("./../../../src/templates/partners.jsx" /* webpackChunkName: "component---src-templates-partners-jsx" */),
  "component---src-templates-player-item-jsx": () => import("./../../../src/templates/playerItem.jsx" /* webpackChunkName: "component---src-templates-player-item-jsx" */),
  "component---src-templates-players-jsx": () => import("./../../../src/templates/players.jsx" /* webpackChunkName: "component---src-templates-players-jsx" */),
  "component---src-templates-press-accreditation-jsx": () => import("./../../../src/templates/press/accreditation.jsx" /* webpackChunkName: "component---src-templates-press-accreditation-jsx" */),
  "component---src-templates-press-logo-jsx": () => import("./../../../src/templates/press/logo.jsx" /* webpackChunkName: "component---src-templates-press-logo-jsx" */),
  "component---src-templates-search-jsx": () => import("./../../../src/templates/search.jsx" /* webpackChunkName: "component---src-templates-search-jsx" */),
  "component---src-templates-shop-jsx": () => import("./../../../src/templates/shop.jsx" /* webpackChunkName: "component---src-templates-shop-jsx" */),
  "component---src-templates-support-partners-jsx": () => import("./../../../src/templates/supportPartners.jsx" /* webpackChunkName: "component---src-templates-support-partners-jsx" */)
}

