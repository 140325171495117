import { useStaticQuery, graphql } from 'gatsby';

const useAllSponsors = () => {
    const {
        allSanitySponsor: { nodes: sponsors },
    } = useStaticQuery(graphql`
        query {
            allSanitySponsor {
                nodes {
                    description
                    enabled
                    logo {
                        asset {
                            url
                            altText
                        }
                    }
                    logoWhite {
                        asset {
                            url
                            altText
                        }
                    }
                    priority
                    url
                    title
                }
            }
        }
    `);

    return sponsors;
};

export default useAllSponsors;
