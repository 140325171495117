import * as React from 'react';

import useAllSponsors from 'data/queries/useAllSponsors';
import * as style from 'styles/components/header/sponsors.module.scss';

const Sponsors = () => {
    const sponsors = useAllSponsors();
    if (sponsors.length === 0) {
        return null;
    }

    return (
        <ul className={`bg ${style.headerSponsors}`}>
            {sponsors
                .filter((item) => item.logoWhite?.asset?.url && item.enabled)
                // Sort those without priority as last, and lower priority as first
                .sort(
                    (a, b) =>
                        (a.priority === null) - (b.priority === null) ||
                        +(a.priority > b.priority) ||
                        -(a.priority < b.priority),
                )
                .map((item, index) => (
                    <li key={index} className={style.headerSponsors__item}>
                        <a href={item.url} target="_blank" rel="noreferrer" title={item.title}>
                            <img
                                src={item.logoWhite.asset.url}
                                alt={item.logoWhite.asset.altText ?? ''}
                            />
                        </a>
                    </li>
                ))}
        </ul>
    );
};

export default Sponsors;
