import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'gatsby';

import { LangContext, defaultLang } from 'context/LangContext';
import { RoutesContext } from 'context/RoutesContext';

const CustomLink = ({
    children,
    to,
    namedRoute,
    className,
    target,
    isTranslated = true,
    urlParams = '',
}) => {
    const { lang } = useContext(LangContext);
    const { routes } = useContext(RoutesContext);

    const [href, setHref] = useState('');
    useEffect(() => {
        if (namedRoute) {
            const routeObject = routes.find(
                (route) => route.node.name === namedRoute && route.node.lang === lang.hreflang,
            );
            setHref(routeObject?.node?.path ? `/${routeObject.node.path}${urlParams}` : '');
        } else if (to) {
            let filteredTo = to;
            if (lang.hreflang !== defaultLang.hreflang && to.charAt(0) === '/') {
                filteredTo = to.substring(1);
            }

            if (isTranslated) {
                setHref(filteredTo + urlParams);
            } else {
                setHref(
                    (lang.hreflang === defaultLang.hreflang ? '' : `/${lang.hreflang}/`) +
                        filteredTo +
                        urlParams,
                );
            }
        }
    }, [to, namedRoute, isTranslated, lang, routes, urlParams]);

    return (
        <Link to={href} className={className} target={target}>
            {children}
        </Link>
    );
};

export default CustomLink;
