import { useCallback } from 'react';

const useRudderStack = () => {
  const track = useCallback((eventName, properties) => {
    if (typeof window.rudderanalytics !== 'undefined') {
      window.rudderanalytics.track(eventName, properties);
    }
  }, []);

  const identify = useCallback((userId, traits) => {
    if (typeof window.rudderanalytics !== 'undefined') {
      window.rudderanalytics.identify(userId, traits);
    }
  }, []);

  return { track, identify };
};

export default useRudderStack;
