import React, { useEffect, useState } from 'react';

export const defaultLang = {
    hreflang: 'se',
    iso: 'sv-SE',
    translationKey: 'se',
};

export const LangContext = React.createContext({
    lang: {},
    switchLang: () => {},
});

export function useLang(pageContextLang) {
    const [lang, setLang] = useState(pageContextLang);

    const switchLang = React.useCallback((newLang) => {
        window.localStorage.setItem('appLang', JSON.stringify(newLang));
        setLang(newLang);

        const urlPrefix = newLang.hreflang === defaultLang.hreflang ? '' : `/${newLang.hreflang}`;
        const pathName = window.location.pathname.toString().replace(`/${newLang.hreflang}`, '');
        window.location.replace(new URL(window.location.origin + urlPrefix + pathName));
    }, [setLang]);

    useEffect(() => {
        const userStoredLang = window.localStorage.getItem('appLang');

        if (userStoredLang) {
            const userLang = JSON.parse(userStoredLang);

            if (userLang.iso !== pageContextLang.iso) {
                switchLang(userLang);
            }
        }
    }, [switchLang, pageContextLang]);

    return { lang, switchLang };
}

export const LangProvider = ({ pageContextLang, children }) => {
    const lang = useLang(pageContextLang);

    // eslint-disable-next-line react/jsx-filename-extension
    return <LangContext.Provider value={lang}>{children}</LangContext.Provider>;
};
