import React, { useState, useMemo, useEffect } from 'react';
import ImageGatsby from 'gatsby-plugin-sanity-image';

import * as style from 'styles/components/utils/arenaOverview.module.scss';
import useArenaOverview from 'data/queries/useArenaOverview';
import { breakpoints } from 'data/utils';

import TextEditorRender from './TextEditorRender';

const ArenaOverview = ({ arenaType = 'men' }) => {

    const arenaOverviewData = useArenaOverview();
    const arenaSections = arenaType === 'men' ? arenaOverviewData.mensArenaSections : arenaOverviewData.womensArenaSections;

    const [isMobile, setIsMobile] = useState(
        typeof window !== 'undefined' && window.innerWidth < breakpoints.medium,
    );

    const refs = useMemo(() => arenaSections.map(() => React.createRef()), [arenaSections]);

    const [activeTab, setActiveTab] = useState(refs[0]);

    const handleSectionChange = (evt) => {
        const selectedRef = refs[evt.target.value];
        setActiveTab(selectedRef);
    };

    useEffect(() => {
        const setMobile = () => {
            if (typeof window !== 'undefined') {
                setIsMobile(window.innerWidth < breakpoints.medium);
            }
        };

        window.addEventListener('resize', setMobile);

        return () => {
            window.removeEventListener('resize', setMobile);
        };
    }, []);

    return (
        <div className={style.arenaInfo}>
            {!isMobile && (
                <ul className="tabs wrapper">
                    {arenaSections.map((section, index) => (
                        <li key={index}>
                            <button
                                className={activeTab === refs[index] ? 'active' : ''}
                                type="button"
                                onClick={() => setActiveTab(refs[index])}
                                aria-label={section.label.se}
                            >
                                {section.label.se}
                            </button>
                        </li>
                    ))}
                </ul>
            )}

            {isMobile && (
                <select name="sectionPicker" id="sectionPicker" className={style.arenaInfo__select} onChange={handleSectionChange}>
                    {arenaSections.map((selectSection, index) => (
                        <option key={index} value={index}>
                            {selectSection.label.se}
                        </option>
                    ))}
                </select>
            )}

            {arenaSections.map((section, index) => (
                <div
                    key={index}
                    ref={refs[index]}
                    className={style.arenaInfo__container}
                    style={{ display: activeTab === refs[index] ? 'flex' : 'none' }}
                >
                    <div className={style.arenaInfo__arenaOverview}>
                        {section.sectionImage.asset && (
                            <div className={style.arenaInfo__arenaImage}>
                                <ImageGatsby
                                    {...section.sectionImage}
                                    style={{
                                        width: '100%',
                                        height: 'auto',
                                    }}
                                    alt={section.label.se}
                                />
                            </div>
                        )}
                    </div>
                    <div className={style.arenaInfo__arenaInfoContainer}>
                        {section.backgroundImage && (
                            <div className={style.arenaInfo__background}>
                                <img src={section.backgroundImage.asset.url} alt="bakgrundsbild" />
                            </div>
                        )}
                        <div className={style.arenaInfo__info}>
                            {section.preTitle &&
                                <h3>{section.preTitle.se}</h3>
                            }
                            <h2 className="title-xs-mobile title-sm text-semibold">
                                {section.title.se}
                            </h2>
                            {section.text &&
                                <TextEditorRender content={section.text.se._rawContent} />
                            }
                            {section.pricing && section.pricing.length > 0 && (
                                <ul className={style.arenaInfo__priceList}>
                                    <li className={style.arenaInfo__priceListHeader}>
                                        <div className={style.arenaInfo__priceListHeaderLabel}>
                                            Säsongskort
                                        </div>
                                        <div className={style.arenaInfo__priceListHeaderPrice}>
                                            Pris
                                        </div>
                                        <div className={style.arenaInfo__priceListHeaderPriceReduced}>
                                            Reducerat pris
                                        </div>
                                    </li>
                                    {section.pricing.map((price, priceIndex) => (
                                        <li key={priceIndex} className={style.arenaInfo__priceListRow}>
                                            <div className={style.arenaInfo__priceListRowLabel}>
                                                {price.label}
                                            </div>
                                            <div className={style.arenaInfo__priceListRowPrice}>
                                                <span>Pris: </span>{price.regularPrice && price.regularPrice}
                                            </div>
                                            <div className={style.arenaInfo__priceListRowPriceReduced}>
                                                <span>Reducerat pris: </span>{price.reducedPrice && price.reducedPrice}
                                            </div>
                                        </li>
                                    ))}
                                </ul>
                            )}
                            {section.ctaHref && section.ctaLabel &&
                                <a className={style.arenaInfo__columnCta} href={section.ctaHref} target="_blank" rel="noreferrer">{section.ctaLabel.se}</a>
                            }

                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
}
export default ArenaOverview;
