import * as React from 'react';

import * as style from 'styles/components/footer/sponsors.module.scss';
import useAllSponsors from 'data/queries/useAllSponsors';

const Sponsors = () => {
    const sponsors = useAllSponsors();

    return (
        <div className={style.footerSponsors}>
            <ul className={`wrapper ${style.footerSponsors__content}`}>
                {sponsors
                    .filter((item) => item.logoWhite?.asset?.url && item.enabled)
                    // Sort those without priority as last, and lower priority as first
                    .sort(
                        (a, b) =>
                            (a.priority === null) - (b.priority === null) ||
                            +(a.priority > b.priority) ||
                            -(a.priority < b.priority),
                    )
                    .map((item, index) => (
                        <li key={index}>
                            <a href={item.url} title={item.title} target="_blank" rel="noreferrer">
                                <img
                                    src={item.logoWhite.asset.url}
                                    alt={item.logoWhite.asset.altText ?? ''}
                                />
                            </a>
                        </li>
                    ))}
            </ul>
        </div>
    );
};

export default Sponsors;
