import { useStaticQuery, graphql } from 'gatsby';

const GetPagesRoutes = () => {
    const {
        allRoutes: { edges: routes },
    } = useStaticQuery(graphql`
        {
            allRoutes {
                edges {
                    node {
                        lang
                        name
                        path
                    }
                }
            }
        }
    `);

    return routes;
};

export default GetPagesRoutes;
