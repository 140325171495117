import * as React from 'react';
import SlickSlider from 'react-slick';

import useAllPartners from 'data/queries/useAllPartners';
import * as style from 'styles/components/footer/partners.module.scss';

import { breakpoints } from '../../data/utils';

const SLIDES = {
    slidesDesktop: 13,
    slidesDesktopSmall: 8,
    slidesTablet: 5,
    slidesMob: 3,
};

const Partners = () => {
    const partners = useAllPartners();

    const sliderSettings = {
        dots: true,
        arrows: false,
        infinite: false,
        mobileFirst: true,
        slidesToShow: SLIDES.slidesDesktop,
        slidesToScroll: SLIDES.slidesDesktop,
        responsive: [
            {
                breakpoint: breakpoints.small,
                settings: {
                    slidesToShow: SLIDES.slidesMob,
                    slidesToScroll: SLIDES.slidesMob,
                },
            },
            {
                breakpoint: breakpoints.medium,
                settings: {
                    slidesToShow: SLIDES.slidesTablet,
                    slidesToScroll: SLIDES.slidesTablet,
                },
            },
            {
                breakpoint: breakpoints.large,
                settings: {
                    slidesToShow: SLIDES.slidesDesktopSmall,
                    slidesToScroll: SLIDES.slidesDesktopSmall,
                },
            },
        ],
    };

    if (partners.length === 0) {
        return null;
    }

    return (
        <div className={style.footerPartners}>
            <div className={`wrapper ${style.footerPartners__content}`}>
                <SlickSlider {...sliderSettings}>
                    {partners
                        .filter((item) => item.enabled)
                        // Sort those without priority as last, and lower priority as first
                        .sort(
                            (a, b) =>
                                (a.priority === null) - (b.priority === null) ||
                                +(a.priority > b.priority) ||
                                -(a.priority < b.priority),
                        )
                        .map((item, index) => (
                            <a href={item.url} key={index} target="_blank" rel="noreferrer">
                                <img
                                    src={item.logoBlack.asset.url}
                                    alt={item.logoBlack.asset.altText ?? ''}
                                />
                            </a>
                        ))}
                </SlickSlider>
            </div>
        </div>
    );
};

export default Partners;
