// extracted by mini-css-extract-plugin
export var footer = "footer-module--footer--d4uJ6";
export var footer__appsContainer = "footer-module--footer__appsContainer--cocWP";
export var footer__column = "footer-module--footer__column--m24XG";
export var footer__container = "footer-module--footer__container--eDwiV";
export var footer__copyright = "footer-module--footer__copyright--oQY9p";
export var footer__description = "footer-module--footer__description--cbpu7";
export var footer__logo = "footer-module--footer__logo--ZtY-H";
export var footer__social = "footer-module--footer__social--pFiWc";
export var menu = "footer-module--menu--LUWO1";
export var menu__item = "footer-module--menu__item--FYU2Z";
export var menu__title = "footer-module--menu__title--XgjIQ";