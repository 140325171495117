import React, { useState } from 'react';

export const RoutesContext = React.createContext({
    routes: [],
    setRoutes: () => {},
});

export const RoutesProvider = ({ initRoutes, children }) => {
    const [routes, setRoutes] = useState(initRoutes);

    return (
        // eslint-disable-next-line react/jsx-filename-extension,react/jsx-no-constructed-context-values
        <RoutesContext.Provider value={{ routes, setRoutes }}>{children}</RoutesContext.Provider>
    );
};
