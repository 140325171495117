import React, { useContext } from 'react';

import CustomLink from 'components/utils/CustomLink';
import { LangContext } from 'context/LangContext';
import * as style from 'styles/components/footer/footer.module.scss';
import FormattedMessage, { getFormattedMessage } from 'components/utils/FormattedMessage';

const Menu = ({ data }) => {
    const { lang } = useContext(LangContext);

    const footerMenus = data.filter((menu) => menu.language.hreflang === lang.translationKey)[0];

    return (
        <div className={style.menu}>
            {footerMenus &&
                footerMenus.items.map((menuItem, menuItemIndex) => (
                    <div key={menuItem.id}>

                        <ul>
                            {menuItem.items
                                .filter((item) => item.title && (item.page || item.externalUrl))
                                .map((item) => (
                                    <li key={item.id}>
                                        {item.page && (
                                            <CustomLink
                                                className={style.menu__item}
                                                to={`/${item.page.slug.current}`}
                                                target={item.external ? '_blank' : '_self'}
                                                isTranslated={false}
                                            >
                                                {item.title}
                                            </CustomLink>
                                        )}

                                        {!item.page && item.externalUrl && (
                                            <a
                                                className={style.menu__item}
                                                href={item.externalUrl}
                                                rel="noreferrer"
                                                target={item.external ? '_blank' : '_self'}
                                            >
                                                {item.title}
                                            </a>
                                        )}
                                    </li>
                                ))}

                            {menuItemIndex === 0 && (
                                <li>
                                    <button
                                        type="button"
                                        id="ot-sdk-btn"
                                        className="ot-sdk-show-settings"
                                        aria-label={getFormattedMessage('footer.cookies', lang)}
                                    >
                                        <FormattedMessage id="footer.cookies" />
                                    </button>
                                </li>
                            )}
                        </ul>
                    </div>
                ))}
        </div>
    );
};

export default Menu;
