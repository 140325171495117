import React from 'react';
import { Script } from 'gatsby';

const LeagueBar = () => (
        <>
            <div className="sef-topbar-widget" />
            <Script async src="https://topbar.svenskelitfotboll.se/top-bar-script.min.js" id="league-top-bar-script" data-allsvenskan="1" data-damallsvenskan="0"/>
        </>
    );

export default LeagueBar;
