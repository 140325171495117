import React, { } from 'react';

import * as style from 'styles/components/header/externalLinks.module.scss';

const ExternalLinks = () => (
        <ul className={style.external__menu}>
            <li>
                <a
                    className='sub-text-nav'
                    href="https://hammarbytff.sportadmin.se/start/?ID=315602"
                    rel='noreferrer'
                    target='_blank'
                >
                    HTFF
                </a>
            </li>
            <li>
                <a
                    className='sub-text-nav'
                    href="https://www.hammarbyungdom.se/start/?ID=122475"
                    rel='noreferrer'
                    target='_blank'
                >
                    Ungdom
                </a>
            </li>
            <li>
                <a
                    className="sub-text-nav"
                    href="https://samhallsmatchen.se/"
                    rel="noreferrer"
                    target="_blank"
                >
                    Samhällsmatchen
                </a>
            </li>
        </ul>
);

export default ExternalLinks;
